<template>
  <div class="poster-wrap">
    <!-- 海报截图的 -->
    <div class="template-img template-img-dom" ref="imageWrapper" >
      <img :src="poster ? poster : src" class="poster-img" ref="coopCachetImg" alt="">
      <div ref="qrcode" class="poster-qrcode"></div>
      <div class="name">{{userInfo ? userInfo.nickname : '酸谈社群'}}</div>
    </div>
    <!-- 展示海报的 -->
    <img :src="poster_url_show" class="poster-show" alt="">
  </div>
</template>

<script>
import {qrcanvas} from 'qrcanvas';
import html2canvas from "html2canvas"
import logoQr from '@/assets/images/user/sour-talk.png';
/*import domtoimage from 'dom-to-image';*/

export default {
  name: "Poster",
  props: ['src','index'],
  data() {
    return {
      poster: '',
      poster_url_show: '',
    }
  },
  created() {

  },
  mounted() {
    if (this.src) {
      let _this = this;
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.getBase64(this.src, dataURL => {
        let dataRes = dataURL.replace(/ +/g, "");
        this.poster = dataRes.replace(/[\r\n]/g, "")
        document.getElementsByClassName('poster-img')[_this.index].onload = function (){
          setTimeout(()=>{
            _this.posterShow();
          },2500)
        }
      })
    }
    if (this.userInfo) {
      this.qrcodeFun()
    }
  },
  methods: {
    // 生成二维码
    qrcodeFun() {
      let _this = this;
      this.$nextTick(() => {
        const image = new Image();
        image.src = logoQr;
        const canvas = qrcanvas({
          data: window.location.origin + '?invite_code=' + this.userInfo.invite_code,
          size: 128,
          logo: {
            image
          }
        })
        _this.$refs.qrcode.innerHTML = '';
        _this.$refs.qrcode.appendChild(canvas);
      })
    },
    // 生成海报
    posterShow() {
      let _this = this;
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      this.$nextTick(()=>{
        (window.html2canvas || html2canvas)(_this.$refs.imageWrapper,{
          useCORS: true,
          backgroundColor: null,
          allowTaint: false,
          height: _this.$refs.imageWrapper.scrollHeight,
          windowHeight: _this.$refs.imageWrapper.scrollHeight,
          width: _this.$refs.imageWrapper.scrollWidth
        }).then(canvas => {
          let dataURL = canvas.toDataURL("image/png");
          _this.poster_url_show = dataURL
        });
      })
    },

    // 图片线上地址转成base64
    getBase64(url, callback) {
      var Img = new Image(),
          dataURL = '';
      Img.src = url;
      Img.setAttribute('crossOrigin', 'Anonymous');
      Img.onload = function () {
        var canvas = document.createElement('canvas'),
            width = Img.width,
            height = Img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
        dataURL = canvas.toDataURL('image/jpeg');
        return callback ? callback(dataURL) : null;
      };
    },
  },
  watch: {
    userInfo(val, oldval) {
      this.qrcodeFun()
      return val
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
}
</script>

<style lang="scss" scoped>
$r: 750/16/1rem;
.poster-wrap{
  width: 620/$r;
  height: 946/$r;
  position: relative;
}
.template-img {
  width: 620/$r;
  height: 946/$r;
  border-radius: 10/$r;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  .poster-img {
    width: 620/$r;
    height: 946/$r;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .poster-qrcode {
    width: 134/$r;
    height: 134/$r;
    display: block;
    position: absolute;
    bottom: 36/$r;
    right: 86/$r;
    z-index: 3;
    background-color: #FFFFFF;
    padding: 8/$r;
    box-sizing: border-box;
    border-radius: 8/$r;
    :deep(canvas) {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .name{
    color: #333333;
    font-size: 20/$r;
    position: absolute;
    left: 91/$r;
    bottom: 145/$r;
    z-index: 3;
  }
}

.poster-show {
  width: 620/$r;
  height: 946/$r;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>
