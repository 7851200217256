<template>
    <!-- 邀请好友 -->
    <div class="invite grey-bg">
        <div class="invite-con">
            <div class="swiper-container">
                <van-swipe class="invite-swiper" indicator-color="#FF6B29" @change="bindchangeSwiper">
                    <van-swipe-item class="invite-swiper-item" v-for="(item,index) in Info" :key="index" v-show="Info.length">
                      <poster :src="item ? item.cover_picture : ''" :index="index" ></poster>
                    </van-swipe-item>
                </van-swipe>
            </div>
            <div class="bot-height safety-height"></div>
            <div class="share-box safety-height">
                <div class="share" @click="shareFun">邀请好友</div>
            </div>
        </div>
        <div>
            <van-overlay :show="guide_status">
                <div class="wrapper align-center" @click.stop>
                    <div class="auth-img ">
                        <img class="auth-icon" src="../../../assets/images/user/auth-icon.png" alt="">
                    </div>
                    <img class="know" src="../../../assets/images/user/know.png" @click="guide_status = false" alt="">
                </div>
            </van-overlay>
        </div>
    </div>
</template>

<script>
import Poster from "components/Poster";
export default {
  name: "address-index",
  data(){
    return{
      Info: [],
      swiper_num: 0,
      guide_status: false
    }
  },
  created() {
    /*(parseFloat(this.userInfo.level) > 1 && process.env.VUE_APP_INVITE == 'true')*/
    if(this.userInfo && (process.env.VUE_APP_INVITE == 'true' || this.userInfo.is_team_master == 1)){
    }else {
      this.$router.replace('/user')
    }
    this.getShow()
  },
  mounted() {
    this.$nextTick(()=>{
      this.$util.domMinHeight()
    })

  },
  methods: {
  //  获取详情
    getShow(){
      let url = this.$api.Invites;
      this.$http.get(url,true).then(res => {
        if(res.data.success){
          let _this = this;
          this.Info = res.data.data;
          if(this.userInfo && this.Info.length){
            let data = this.Info[0];
            let about = ''
            if(data && (data.about == null || data.about =='')){
              about = _this.userInfo.nickname ? _this.userInfo.nickname + '邀你一起加入酸谈社群' : '' + '邀你一起加入酸谈社群'
            }else {
              about = data ? data.about : _this.userInfo.nickname ? _this.userInfo.nickname + '邀你一起加入酸谈社群' : '' + '邀你一起加入酸谈社群'
            }
            this.$wxShare.wxShare(data.title,about,data.cover_picture, _this.userInfo.invite_code)
          }
        }
      })
    },
    // 商品图发生改变时
    bindchangeSwiper(index) {
      this.swiper_num = index
    },
  //  邀请好友点击
    shareFun(){
      let _this = this;
      _this.guide_status = true;
      let data = this.Info[this.swiper_num];
      let about = ''
      if(data.about == null || data.about ==''){
        about = _this.userInfo.nickname ? _this.userInfo.nickname + '邀你一起加入酸谈社群' : '' + '邀你一起加入酸谈社群'
      }else {
        about = data ? data.about : _this.userInfo.nickname ? _this.userInfo.nickname + '邀你一起加入酸谈社群' : '' + '邀你一起加入酸谈社群'
      }
      this.$wxShare.wxShare(data.title,about,data.cover_picture,_this.userInfo.invite_code)
    }
  },
  computed: {
    userInfo(){
      return this.$store.getters.userInfo;
    }
  },
  components: {
    Poster
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/invite/invite.scss";
</style>
